import { ISliderSnippet } from '@netfront/ekardo-content-library';

export const HOME_QUOTE_SLIDER_DATA: ISliderSnippet = {
  autoplay: true,
  autoplaySpeed: 5000,
  dots: true,
  id: 8880,
  infinite: true,
  sliderItems: [
    {
      author: 'Wife and Supporter.',
      id: 8881,
      quote: 'It changed everything, but he would’ve done the same for me, you know, without <br /> question… it was a process of love...',
      type: 'QUOTE',
    },
    {
      author: 'Daughter and Supporter.',
      id: 8882,
      quote: 'It was comforting for us all to be there together for Dad… it brought us all closer together.',
      type: 'QUOTE',
    },
    {
      author: 'Friend  and Supporter.',
      id: 8883,
      quote:
        "I think if you've gone through it, you can relate to it and pass your information on that might just help one person… I can't change my situation, but if it's going to help someone else, then it's all worth it. Definitely.",
      type: 'QUOTE',
    },
    {
      author: 'Husband   and Supporter.',
      id: 8884,
      quote:
        'Every day is tough on us all, it is definitely hard watching my wife go through this but much harder watching my children know that mummy is sick. So happy to have found this project, it is hard talking to people that are not going through it.',
      type: 'QUOTE',
    },
  ],
  slidesToShow: 1,
  speed: 1000,
  title: '',
};
