import { useEffect, useState } from 'react';

import { SliderSnippet } from '@netfront/ekardo-content-library';

import { HOME_QUOTE_SLIDER_DATA } from './HomeQuoteSlider.constants';

const HomeQuoteSlider = () => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => setIsMounted(true), []);

  return isMounted ? (
    <div className="c-home-quote-slider">
      <SliderSnippet slider={HOME_QUOTE_SLIDER_DATA} />
    </div>
  ) : null;
};

export { HomeQuoteSlider };
