import cx from 'classnames';

/**
 *
 * @returns Presentational markup for affiliate logos
 */
const Affiliates = ({ isAddYMargin = false }) => {
  return (
    <section
      className={cx('my-0 mx-auto container-affiliates', {
        'my-12': isAddYMargin,
      })}
    >
      <div className="bg-white container-md h-1 mb-8 radius-full" role="presentation" />
      <div className="flex flex-wrap md:flex-row md:py-32 py-12">
        <div className="align-center flex justify-center  md:w-third w-half">
          <img alt="University of Newcastle logo" className="md:w-32 w-24" loading="lazy" src="/images/university-of-newcastle-logo.svg" />
        </div>
        <div className="align-center flex justify-center mb-8 md:mb-0 md:w-third w-half">
          <img alt="Mark hughes foundation logo" className="w-24" loading="lazy" src="/images/mark-hughes-foundation-logo.svg" />
        </div>
        <div className="align-center flex justify-center mb-8 md:mb-0 md:w-third w-half">
          <img alt="Hunter Medical Research Institute logo" className="w-24" loading="lazy" src="/images/hmri-logo.png" />
        </div>
      </div>
    </section>
  );
};

export { Affiliates };
