import { ISliderSnippet } from '@netfront/ekardo-content-library';

export const FEATURES_SLIDER_DATA: ISliderSnippet = {
  autoplay: true,
  autoplaySpeed: 5000,
  dots: true,
  id: 7770,
  infinite: true,
  sliderItems: [
    {
      id: 7771,
      text: '<div class="flex flex-col md:flex-row"><div class="align-center flex flex-col justify-center md:w-third p-8 text-center w-full"><img loading="lazy" src="/images/research-full-icon.svg" alt="Beanie"></div><div class="md:w-3/4 mb-4 md:mb-0 md:p-8 w-full flex align-center"><div class="text-left"><span class="block color-accent h4 mb-6 weight-800">Research</span><h2 class="color-blue-900 h3 mb-12">We want to see better outcomes for people with brain cancer and their families.</h2><a class="cursor color-accent flex font-header inline-align-center text-uppercase weight-700" href="/about-us">Learn more<svg class="ml-2" fill="none" stroke="currentColor" viewBox="0 0 26 26" width="18" xmlns="http://www.w3.org/2000/svg"><path d="M9 5l7 7-7 7" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"></path></svg></a></div></div></div>',
      type: 'CODE',
    },
    {
      id: 7772,
      text: '<div class="flex flex-col md:flex-row"><div class="align-center flex flex-col justify-center md:w-third p-8 text-center w-full"><img loading="lazy" src="/images/support-full-icon.svg" alt="Beanie"></div><div class="md:w-3/4 mb-4 md:mb-0 md:p-8 w-full flex align-center"><div class="text-left"><span class="block color-accent h4 mb-6 weight-800">Support & Care</span><h2 class="color-blue-900 h3 mb-12">The goal of this online brain cancer program is to support and prepare friends and family members to cope.</h2><a class="cursor color-accent flex font-header inline-align-center text-uppercase weight-700" href="/register">Learn more<svg class="ml-2" fill="none" stroke="currentColor" viewBox="0 0 26 26" width="18" xmlns="http://www.w3.org/2000/svg"><path d="M9 5l7 7-7 7" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"></path></svg></a></div></div></div>',
      type: 'CODE',
    },
    {
      id: 7773,
      text: '<div class="flex flex-col md:flex-row"><div class="align-center flex flex-col justify-center md:w-third p-8 text-center w-full"><img loading="lazy" src="/images/information-full-icon.svg" alt="Beanie"></div><div class="md:w-3/4 mb-4 md:mb-0 md:p-8 w-full flex align-center"><div class="text-left"><span class="block color-accent h4 mb-6 weight-800">Information</span><h2 class="color-blue-900 h3 mb-12">We are committed to delivering the right information, at the right time.</h2><a class="cursor color-accent flex font-header inline-align-center text-uppercase weight-700" href="/resources">Learn more<svg class="ml-2" fill="none" stroke="currentColor" viewBox="0 0 26 26" width="18" xmlns="http://www.w3.org/2000/svg"><path d="M9 5l7 7-7 7" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"></path></svg></a></div></div></div>',
      type: 'CODE',
    },
    {
      id: 7774,
      text: '<div class="flex flex-col md:flex-row"><div class="align-center flex flex-col justify-center md:w-third p-8 text-center w-full"><img loading="lazy" src="/images/connect-full-icon.svg" alt="Beanie"></div><div class="md:w-3/4 mb-4 md:mb-0 md:p-8 w-full flex align-center"><div class="text-left"><span class="block color-accent h4 mb-6 weight-800">Connect</span><h2 class="color-blue-900 h3 mb-12">With the real stories of brain cancer supporters across Australia.</h2><a class="cursor color-accent flex font-header inline-align-center text-uppercase weight-700" href="/our-research">Learn more<svg class="ml-2" fill="none" stroke="currentColor" viewBox="0 0 26 26" width="18" xmlns="http://www.w3.org/2000/svg"><path d="M9 5l7 7-7 7" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"></path></svg></a></div></div></div>',
      type: 'CODE',
    },
  ],
  slidesToShow: 1,
  speed: 1000,
  title: '',
};
