import { useEffect, useState } from 'react';

import { SliderSnippet } from '@netfront/ekardo-content-library';

import { FEATURES_SLIDER_DATA } from './FeaturesSlider.constants';

const FeaturesSlider = () => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => setIsMounted(true), []);

  return isMounted ? (
    <div className="c-features-slider">
      <SliderSnippet slider={FEATURES_SLIDER_DATA} />
    </div>
  ) : null;
};

export { FeaturesSlider };
