import { ISliderSnippet } from '@netfront/ekardo-content-library';

export const INTRO_SLIDER_DATA: ISliderSnippet = {
  autoplay: true,
  autoplaySpeed: 5000,
  dots: true,
  id: 9990,
  infinite: true,
  sliderItems: [
    {
      id: 9991,
      text: '<div class="flex flex-col md:flex-row"><div class="align-center flex flex-col justify-center md:w-third p-8 text-center w-full"><img loading="lazy" alt="Beanie" src="/images/beanie-full-icon.svg"></div><div class="md:w-3/4 mb-4 md:mb-0 md:p-8 w-full flex align-center"><h2 class="color-blue-900 h3 text-left">Thanks to donations, the Mark Hughes Foundation can fund brain cancer research and support for friends and families.</h2></div></div>',
      type: 'CODE',
    },
    {
      id: 9992,
      text: '<div class="flex flex-col md:flex-row"><div class="align-center flex flex-col justify-center md:w-third p-8 text-center w-full"><img loading="lazy" alt="Cartoon of brain" src="/images/brain-full-icon.svg"></div><div class="md:w-3/4 mb-4 md:mb-0 md:p-8 w-full flex align-center"><h2 class="color-blue-900 h3 text-left">The care and support provided by friends and family is an important part of the overall quality of care for people with brain cancer.</h2></div></div>',
      type: 'CODE',
    },
    {
      id: 9993,
      text: '<div class="flex flex-col md:flex-row"><div class="align-center flex flex-col justify-center md:w-third p-8 text-center w-full"><img loading="lazy" alt="Cartoon of user engaging with the CarerWell program" src="/images/aus-full-icon.svg"></div><div class="md:w-3/4 mb-4 md:mb-0 md:p-8 w-full flex align-center"><h2 class="color-blue-900 h3 text-left">Australian brain cancer supporters take on a caring role they are often not prepared or trained for.</h2></div></div>',
      type: 'CODE',
    },
    {
      id: 9994,
      text: '<div class="flex flex-col md:flex-row"><div class="align-center flex flex-col justify-center md:w-third p-8 text-center w-full"><img loading="lazy" alt="Cartoon of user engaging with the CarerWell program" src="/images/band-aid-icon.svg"></div><div class="md:w-3/4 mb-4 md:mb-0 md:p-8 w-full flex align-center"><h2 class="color-blue-900 h3 text-left">While rewarding, supporting someone with brain cancer can lead to increased stress, anxiety, or depression, and lower levels of wellbeing and quality of life.</h2></div></div>',
      type: 'CODE',
    },
    {
      id: 9995,
      text: '<div class="flex flex-col md:flex-row"><div class="align-center flex flex-col justify-center md:w-third p-8 text-center w-full"><img loading="lazy" alt="Cartoon of user engaging with the CarerWell program" class="w-full" src="/images/carer-well-logo-slider.svg"></div><div class="md:w-3/4 mb-4 md:mb-0 md:p-8 w-full flex align-center"><h2 class="color-blue-900 h3 text-left">Carerwell provides information, support, and strategies for brain cancer supporters via this website and an online program.</h2></div></div>',
      type: 'CODE',
    },
  ],
  slidesToShow: 1,
  speed: 1000,
  title: '',
};
